$indigo: #1F384F;
$coral: #F38971;
$gray: #515151;

$accent-colour: $coral;
$highlighted-background-colour: $indigo;
$front-background-colour: black;
$back-background-colour: white;
$header-text-colour: white;
$body-text-colour: black;
$light-text-colour: #404040;
$main-border-radius: 4px;
$select-option-background-color: white;

$icon-colour-alternative: #e3e3e3;

$card-content-background-colour: $front-background-colour;
$sidebar-background-colour: $front-background-colour;
$header-background-colour: $front-background-colour;
$link-colour: $coral;
$heading-font-family: 'Trois Mille', 'Roboto', 'Segoe', 'Candara', 'Calibri',
    'Arial', sans-serif;
$body-font-family: 'Aeonik', 'Roboto', 'Segoe', 'Candara', 'Calibri', 'Arial',
    sans-serif;

@use 'sass:color';

@forward '~@cognitranlimited/itis-web/dist/styles' with (
    $colour-accent: $accent-colour,
    $body-background: $back-background-colour,
    $body-font-family: $body-font-family,
    $body-text-colour: $body-text-colour,
    $Link-text-colour: $link-colour,
    $Link-text-colour-hover: darken($link-colour, 4),
    $Link-title-text-colour: black,
    $Link-title-text-colour-hover: #191818,
    $Link-title-text-colour-active: black,
    $heading-font-family: $heading-font-family,
    $heading-text-colour: $accent-colour,
    $well-background: $highlighted-background-colour,
    $well-text-colour: white,
    $input-background: white,
    $input-border: 1px solid $highlighted-background-colour,
    $input-background-disabled: color.scale($highlighted-background-colour, $alpha: -80%),
    $input-text-colour-disabled: black,
    $input-text-colour: black,
    $app-background: $back-background-colour,
    $document-background: white,
    $light-text-colour: $light-text-colour,
    $success-text-colour: #379d37,
    $error-text-colour: #e65b58,
    $light-border: 1px solid $gray,
    $Header-icon-colour: white,
    $TableHeader-title-text-colour: $header-text-colour,
    $SidebarFooter-text-colour: white,
    $Sidebar-background: $sidebar-background-colour,
    $SidebarSection-background: $indigo,
    $SidebarSection-text-colour: white,
    $Sidebar-light-text-colour: #aeaeae,
    $Button-background: $accent-colour,
    $Button-text-colour: white,
    $Button-background-outlined: $accent-colour,
    $Button-background-outlined-hover: darken($accent-colour, 3%),
    $Button-text-colour-outlined: white,
    $Button-text-colour-outlined-hover: white,
    $Button-text-colour-outlined-active: white,
    $Button-text-colour-danger: white,
    $Panel-background: white,
    $Panel-text-colour: black,
    $Panel-header-text-colour: $header-text-colour,
    $Panel-header-font-weight: normal,
    $Panel-header-background: $indigo,
    $Panel-info-background: white,
    $Panel-info-icon-colour: $coral,
    $Panel-header-padding: 12px,
    $Panel-border: 2px solid $front-background-colour,
    $Panel-border-radius: $main-border-radius,
    $IconButton-icon-colour: $accent-colour,
    $IconButton-icon-colour-disabled: color.scale($accent-colour, $alpha: -80%),
    $Header-background: $header-background-colour,
    $ContentHeader-title-font-size: 24px,
    $ContentHeader-title-text-colour: $header-text-colour,
    $ContentHeader-subtitle-text-colour: $header-text-colour,
    $VerticalTabBar-font-size: 18px,
    $VerticalTabBar-text-colour: black,
    $Table-header-background: $indigo,
    $Table-header-background-active: darken(white, 4),
    $Table-header-text-colour: white,
    $Table-header-font-size: 15px,
    $Table-header-box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1), // Make sure outlines show on tables
    $Table-background: white,
    $Table-text-colour: black,
    $VehicleHistoryPopover-header-font-size: 18px,
    $VehicleHistoryList-details-font-size: 14px,
    $NewLookPanel-background: white,
    $SearchBox-background: $highlighted-background-colour,
    $SearchBox-sidebar-background: $highlighted-background-colour,
    $SearchBox-text-colour: white,
    $SearchBox-sidebar-text-colour: white,
    $SearchOptions-label-text-colour: white,
    $DocumentLabel-text-colour: $front-background-colour,
    $MessageTableRow-read-background: darken(white, 2),
    $HeaderSearch-background: $highlighted-background-colour,
    $TickBox-sidebar-ticked-colour: black,
    $TickBox-ticked-colour: black,
    $TickBox-unticked-colour: black,
    $badge-background: $coral,
    $badge-text-colour: black,
    $badge-font-weight: bold,
    $FormField-label-font-family: $body-font-family,
    $FormField-label-text-colour: black,
    $CentredMessage-text-colour: black,
    $DefinitionList-background: white,
    $DefinitionList-term-text-colour: black,
    $DefinitionList-definition-text-colour: black,
    $notification-text-colour: black,
    $Pagination-text-colour: white,
    $DeleteAccountTab-header-text-colour: $header-text-colour,
    $DocumentLabel-background-new: $accent-colour,
    $DocumentLabel-text-colour-new: black
);

@use '~@cognitranlimited/itis-web/dist/styles';

@use '~@cognitranlimited/itis-web/dist/styles/campaigns' with (
    $CampaignsBadge-background: firebrick,
    $CampaignsBadge-text-colour: white
);

// Livewire fonts
@import './fonts/fonts.scss';

/**
 * Temporary overrides (these should have a corresponding core case to avoid accumulating styling overrides)
**/

// Dropdowns, Temporary override for recent VIN dropdown
.MuiPaper-root {
    background-color: white !important;
    color: black !important;

    .MuiList-root {
        background-color: white !important;

        .MuiMenuItem-root {
            background-color: white !important;
            color: black !important;
        }
    }
}

// Panel
.Panel-message {
    color: black !important;
}

.AccountForm_Optional {
    color: black !important;
}

@media screen and (max-width: 681px) {
    .Panel {
        border-right: none;
        border-left: none;
        box-shadow: none;
    }
}

// Dialogue
.Dialogue {
    border: 2px solid black !important;

    h2, h3, .IconButton {
        color: black !important;
    }

    .Dialogue-header {
        min-height: inherit;
    }

    .Dialogue-headerBorder {
        border-color: black !important;
    }
}

// Table
.Table tbody tr:not(:last-child) {
    border-bottom: 1px solid rgba($gray, 0.3); // make sure table borders are visible
}

.Table tbody tr {
    border-left: none;

    .MuiSvgIcon-root {
        color: black !important;
    }
}

.Table thead th {
    border-radius: 0px !important;

    .MuiSvgIcon-root {
        color: white !important;
    }
}

// Selects
.MuiNativeSelect-select:not([multiple]) {
    option,
    optgroup {
        color: black !important;
        background-color: $select-option-background-color !important;
    }
}

.LocalisedDocumentSelect {
    .MuiSelect-select {
        color: black !important;

        ul {
            color: black !important;
        }
    }

    &-subtitle {
        color: black !important;
    }
}

// Subscriptions
.SubscriptionLabel {
    text-align: center;
    white-space: nowrap;
}

.SubscriptionsTab-subscriptionsPanel {
    .TableHeader {
        background-color: white !important;

        h3, .Pagination_Text {
            color: black !important;
        }
    }
}

// Expander
.Expander {
    &-expand {
        min-width: 55px;
        color: black !important;
    }

    &-header {
        height: inherit;
        min-height: 50px;
    }

    &-subtitle {
        .SubscriptionLabel {
            color: black !important;
            border-color: black !important;
        }
    }

    h4 {
        padding: 15px 0px;
    }
}

// Misc
li.MuiMenuItem-root {
    background: $front-background-colour;
}

.DocumentTableRow-unavailable {
    color: black !important;
}

.SearchOptions {
    .MuiCheckbox-root {
        color: $coral !important;
    }

    label {
        color: white;
    }
}

.VehicleHistoryList-empty {
    color: black !important;
}

.VehiclePurchaseSubscriptionPanel {
    a {
        color: black !important;
    }
}

.DocumentSearchFilter {
    &-default {
        color: styles.$SearchBox-placeholder-colour;
    }

    .MuiSelect-select {
        color: styles.$SearchBox-text-colour;
    }

    svg.MuiSelect-icon {
        color: styles.$SearchBox-icon-colour;
    }
}

.MuiToggleButton-primary.Mui-selected {
    color: white !important;
    background: $accent-colour !important;
}
