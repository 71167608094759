@use '../common';

.ISheetSearchPanel {
    form {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &-input {
        display: flex;
        align-items: stretch;
        max-width: 350px;

        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &-error {
        color: common.$error-text-colour;
    }

    &-results {
        margin-top: 20px;
    }

    &-resultsHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}
