@font-face {
    font-family: Aeonik;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('./Aeonik-Regular.otf') format('opentype');
}

@font-face {
    font-family: Aeonik;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('./Aeonik-Bold.otf') format('opentype');
}

@font-face {
    font-family: Roboto Mono;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('./RobotoMono.ttf') format('ttf');
}

@font-face {
    font-family: Signal Mono;
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url('./SignalMono-Light.woff2') format('woff2');
}

@font-face {
    font-family: Trois Mille;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('./TroisMilleMedium.woff2') format('woff2');
}
