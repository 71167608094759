@use '../common';

.ISheetSearchTableRow {
    &-languages {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .MobileTableCell-primary {
        font-size: common.$large-font-size;
        padding-bottom: 5px;
    }

    .MobileTableCell-date {
        font-size: common.$body-font-size;
    }
}
