.ISheetHelpPanel {
    &-content {
        display: flex;
        flex-direction: column;

        p {
            padding-bottom: 8px;
        }
    }

    &-carousel {
        max-width: 800px;
        place-self: center;
        margin-top: 15px;
    }
}
